import React from 'react';
import { ListChallengesResponse } from '@wix/ambassador-challenges-v1-challenge/types';
import { useControllerData } from '../main/controllerContext';

export interface IChallengesListDataContext {
  challengesListData: ListChallengesResponse;
  programPageURI: string; // to build the right links from list to the certain program page
}

export const ChallengesListDataContext =
  React.createContext<IChallengesListDataContext>({
    challengesListData: null,
    programPageURI: null,
  });

export const useChallengesListData = () => {
  const props = useControllerData();
  return {
    challengesListData: props.challengesListData,
    programPageURI: props.programPageURI,
    leaveProgram: props.leaveProgram,
    cancelJoinRequest: props.cancelJoinRequest,
  };
};
